/////React
import React, { useState, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

import {
  UploadOutlined,
  DeleteOutlined,
  StopOutlined,
  FileSearchOutlined,
  TeamOutlined,
  SolutionOutlined,
  CarryOutOutlined,
  EyeOutlined,
} from "@ant-design/icons";

////Layout Sucess
import Sucess from "../components/responses/Sucess";
import TipoForm from "./modals/formularioPerfil/TipoForm";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Button,
  PageHeader,
  Tabs,
  Typography,
  notification,
  Upload,
  Modal,
  DatePicker,
  TimePicker,
  Switch,
  Space,
  List,
  Tooltip,
  Popconfirm,
  Timeline,
  InputNumber,
} from "antd";

////Locale
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import Vista from "./visualizacion/Vista";
import Entrevista from "./modals/formularioPerfil/Entrevista";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

///Componente Form
const FormularioPerfil = ({ usuario, correoLogeado }) => {
  const [nombre, , correoLOgueado] = usuario;
  const [update, setUpdate] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);

  const [modalSucessdenegado, setModalSucessdenegado] = useState(false);
  const [modalSucess, setModalSucess] = useState(false);
  const [modalSucessPC, setModalSucessPC] = useState(false);
  const [modalPP, setmodalPP] = useState(false);
  const [modalPC, setmodalPC] = useState(false);
  const [modalEntrevista, setmodalEntrevista] = useState(false);
  const [modalVPC, setmodalVPC] = useState(false);
  const [modalSucessAsignarEntrevista, setmodalSucessAsignarEntrevista] =
    useState(false);
  const [modalHistorial, setmodalHistorial] = useState(false);

  const [datosCargaHistorial, setdatosCargaHistorial] = useState([]);
  const [datosCarga, setdatosCarga] = useState(null);
  const [datosCargaComentarios, setdatosCargaComentarios] = useState(null);
  const [botonEnviarComentario, setbotonEnviarComentario] = useState(false);
  const [botonAsignarEntrevista, setbotonAsignarEntrevista] = useState(false);
  const [botonEnvioPP, setbotonEnvioPP] = useState(false);
  const [botonEnvioPC, setbotonEnvioPC] = useState(false);
  const [virtual, setVirtual] = useState(false);
  const [botonEnviarAdjuntosPerfil, setbotonEnviarAdjuntosPerfil] =
    useState(false);
  const [puestos, setPuestos] = useState(null);
  const [cecosNuevos, setCecosNuevos] = useState([]);
  const history = useHistory();
  const [item, setItem] = useState([]);

  ////Variables de Form
  const [formAdjuntosPerfil] = Form.useForm();
  const [form] = Form.useForm();
  const [formPP] = Form.useForm();
  const [formPC] = Form.useForm();
  const [formVPC] = Form.useForm();
  const [formAsignarEntrevista] = Form.useForm();
  ////Fin Variables de Form

  const [Easignado, setEasignado] = useState(false);
  const [VEntrevista, setVEntrevista] = useState(false);
  const [contratado, setContratado] = useState(false);
  const [pcontratado, setPContratado] = useState(false);

  ///carga de ID recibido
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");

  const cambioEntrevista = (checked) => {
    setVirtual(!virtual);
    console.log(`switch to ${checked}`);
  };

  const CargaProyectoPuesto = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const queryGraphQlPuestos = JSON.stringify({
      query: `query {
        puestos(pagination: { limit: -1 }) {
          data {
            id
            attributes {
              puestosTrabajos
            }
          }
        }
      }`,
      variables: {},
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/graphql",
      queryGraphQlPuestos,
      { headers }
    );

    setPuestos(data.data.puestos.data);

    const queryGraphQl = JSON.stringify({
      query: `query {
        departamentoCecos(pagination: { limit: -1 }) {
          data {
            id
            attributes {
              departamento
              nombreCeco
            }
          }
        }
      }`,
      variables: {},
    });

    await Axios.post("https://rh.api.prontobpo.cloud/graphql", queryGraphQl, {
      headers,
    })
      .then((res) => {
        const data = res.data.data.departamentoCecos.data;

        // Objeto para mantener un registro de los departamentos únicos
        const departamentosUnicos = {};

        // Procesa los datos para agrupar los nombreCeco bajo un solo departamento
        data.forEach((item) => {
          const { departamento, nombreCeco } = item.attributes;

          // Verifica si el departamento ya ha sido agregado
          if (!departamentosUnicos[departamento]) {
            // Si no ha sido agregado, inicializa el departamento con un array vacío
            departamentosUnicos[departamento] = [];
          }

          // Agrega el nombreCeco al departamento correspondiente
          departamentosUnicos[departamento].push({
            value: nombreCeco,
            label: nombreCeco,
          });
        });

        // Formatea los datos para que coincidan con la estructura requerida por el Cascader
        const processedData = Object.entries(departamentosUnicos).map(
          ([departamento, nombreCecos]) => ({
            value: departamento,
            label: departamento,
            children: nombreCecos,
          })
        );

        // Ordenar alfabéticamente por la propiedad label
        processedData.sort((a, b) => a.label.localeCompare(b.label));

        // Establece los datos procesados en el estado
        setCecosNuevos(processedData);

        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //////envio de paginas

  //////Regreso a Perfil
  const enviarPagina = () => {
    history.goBack();
  };

  function callback(key) {
    console.log(key);
  }

  //////Visual de solicitud de empleo
  const EnviarVisualSolicitud = (id) => {
    history.push({
      pathname: "Formulario2", ///Direccion a donde dirige
      search: `?id=${id}`, //parametros a enviar
      state: {
        //Estado
        update: true,
      },
    });
  };
  //////Fin Visual de solicitud de empleo

  /////Carga Visual PC
  const visualPC = async (id) => {
    setmodalVPC(true);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/primercontactos?filters[idMother][$eq]=${id}`,
      { headers }
    );

    formVPC.setFieldsValue({
      nombreV: data.data[0].attributes.nombre,
      dpiV: data.data[0].attributes.dpi,
      correoV: data.data[0].attributes.correo,
      nitTramitadoV: data.data[0].attributes.nitTramitado,
      disponibilidadLaboralV: data.data[0].attributes.disponibilidadLaboral,
      restriccionHorarioV: data.data[0].attributes.restriccionHorario,
      pretensionSalarialV: data.data[0].attributes.pretensionSalarial,
      explicacionPagoV: data.data[0].attributes.explicacionPago,
    });

    return data;
  };
  /////fin carga visual PC

  ////carga Datos
  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?populate[mother]=*`,
      { headers }
    );

    setdatosCarga(data.data);

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${data.data.attributes.mother.data.id}`,
      { headers }
    );

    if (
      dataasignados.data.data.length > 0 &&
      dataasignados.data.data[0].attributes.estado === "Completada" &&
      data.data.attributes.estado === "Entrevista"
    ) {
      setEasignado(true);
      setVEntrevista(true);
    } else if (data.data.attributes.estado === "Oferta") {
      setEasignado(false);
      setVEntrevista(true);
    } else if (data.data.attributes.estado === "Contratado") {
      setEasignado(false);
      setVEntrevista(true);
      setContratado(true);
    }

    return data;
  };

  const validacionPCEffect = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${datosCarga.attributes.mother.data.id}`,
      { headers }
    );
    console.log("respuesta de la asignacion de PPE", data);

    if (data.data.length > 0) {
      console.log("Ya hay primer contacto PPE");
      visualPC(datosCarga.attributes.mother.data.id);
      setmodalVPC(true);
    } else {
      setmodalPC(true);
    }
    return data;
  };

  const cargaArchivos = async () => {
    try {
      const response = await Axios.post(
        "https://rh.api.prontobpo.cloud/graphql", // Reemplaza con la URL correcta de tu endpoint GraphQL
        {
          query: `query {
  perfiles(filters: { id: { eq: ${idRequerimiento} } }) {
    data {
      id
      attributes {
        adjuntos {
          data {
            id
            attributes {
              name
              url
              previewUrl
            }
          }
        }
        requerimiento{
           data{
            id
            attributes{
              proyecto
            }
          }
        }
      }
    }
  }
}`,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("datos del contratado", response);

      setItem(response.data.data.perfiles.data[0].attributes.adjuntos.data);
      setPContratado(
        response.data.data.perfiles.data[0].attributes.requerimiento.data
          .attributes.proyecto
      );

      return;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  ////carga historial
  const cargaHistorial = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/logs?filters[idMother][$eq]=${datosCarga.attributes.mother.data.id}`,
      { headers }
    );

    setdatosCargaHistorial(data.data);
    setmodalHistorial(true);
    return data;
  };

  const cargaDatosComentarios = async (idMother) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const dataComentarios = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/comentarios?filters[idMother][$eq]=${idMother}&_sort=id:desc`,
      { headers }
    );

    console.log("Data comentarios", dataComentarios);

    setdatosCargaComentarios(dataComentarios.data.data);

    return dataComentarios;
  };

  const actualizarComentarios = (data) => {
    // Crear un nuevo array para almacenar los comentarios unificados
    const comentariosUnificados = [];

    // Agregar los comentarios de la primera variable al nuevo array
    comentariosUnificados.push({
      attributes: {
        comentario: data.data.comentario,
        fecha: data.data.fecha,
        hora: data.data.hora,
        idMother: data.data.idMother,
        persona: data.data.persona,
      },
      id: 0, // Asignar un nuevo ID, puedes ajustarlo según tu lógica
    });

    // Recorrer los comentarios de la segunda variable y agregarlos al nuevo array
    datosCargaComentarios.forEach((comentario) => {
      comentariosUnificados.push(comentario);
    });

    // Ahora comentariosUnificados contiene todos los comentarios en un solo array con la estructura de la segunda variabl

    setdatosCargaComentarios(comentariosUnificados);
  };

  const correoPerfilDeclinado = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga?.attributes.mother.data.attributes.correo,
      nombre: datosCarga?.attributes.nombre,
      asunto: "Terminacion proceso aplicacion plaza",
      mensaje:
        "Gracias por tu interés en aplicar con nosotros. Por el momento hemos decidido no continuar con tu proceso de selección, sin embargo agradecemos enormemente tu tiempo e interés en Pronto BPO, Te invitamos a que continúes pendiente de nuestras plazas y no dudes en aplicar si consideras llenas los requisitos",
    };

    const datos = JSON.stringify(datosEnviar);

    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/denegar`,
      datos,
      { headers }
    );
    return data;
  };

  //////LogPPP
  const agregadoLogDeclinadoPerfil = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Denegado en opcion de perfil",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga?.attributes.mother.data.id,
      },
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    return data;
  };
  ////Final LogPPP

  //////LogPPP
  const agregadoLogBajaRegistro = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Dado de baja en el app",
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga?.attributes.mother.data.id,
      },
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    return data;
  };
  ////Final LogPPP

  const denegarSolicitudEmpleo = async () => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Declinado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    correoPerfilDeclinado();
    agregadoLogDeclinadoPerfil();
    setModalSucessdenegado(true);

    return dataStatus;
  };

  const bajaRegistro = async () => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Eliminado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    correoPerfilDeclinado();
    agregadoLogBajaRegistro();
    setModalSucessdenegado(true);

    return dataStatus;
  };

  //////LogPPP
  const agregadoLogComentarios = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Comentario perfil",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga.attributes.mother.data.id,
      },
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    return data;
  };
  ////Final LogPPP

  const envioSolicitudRequermiento = async (valores) => {
    const datosEnviar = {
      data: {
        comentario: valores.comentariosPerfil,
        persona: nombre,
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        idMother: datosCarga.attributes.mother.data.id,
      },
    };

    actualizarComentarios(datosEnviar);

    const datos = JSON.stringify(datosEnviar);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/comentarios",
      datos,
      { headers }
    );

    agregadoLogComentarios();

    return data;
  };

  //////Formulario
  const onFinish = (values) => {
    setbotonEnviarComentario(true);

    envioSolicitudRequermiento(values)
      .then((values) => {
        setbotonEnviarComentario(false);
        form.resetFields();
        notification["info"]({
          message: "Comentarios",
          description: "Se guardo comentario con éxito.",
        });
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setbotonEnviarComentario(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    notification["warning"]({
      message: "Comentarios",
      description: "No se pudieron cargar los comentarios.",
    });
    console.log("Failed:", errorInfo);
  };

  const updateFilesListHook = async (file) => {
    //const files = defaultFileList.push(file);
    return setDefaultFileList([...defaultFileList]);
  };

  const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;

    var formData = new FormData();

    formData.append("files", file);
    await Axios.post("https://rh.api.prontobpo.cloud/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((data) => {
        //console.log("* UploadData", data);
        updateFilesListHook(data.data[0]);
        setItem([...item, data.data[0]]);
        onSuccess("Ok");
      })
      .catch((error) => {
        onError({ event: error });
        //console.log(">>>erro file", error);
      });
  };

  ///  Delete File Strapi
  const deleteStrapiFile = async (fileId) => {
    await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/upload/files/${fileId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  //onRemove
  const onRemoveUpoload = (files) => {
    const nameFile = files.name;

    const deleteFileStrapi = defaultFileList.filter(function (i, n) {
      return i.name === nameFile;
    });

    const removeID = deleteFileStrapi[0].id;

    deleteStrapiFile(removeID);

    const valor = defaultFileList.filter(function (i, n) {
      return i.name !== nameFile;
    });

    setDefaultFileList([...valor]);
  };

  //////Formulario
  const onFinishAdjuntosPerfil = () => {
    setbotonEnviarAdjuntosPerfil(true);

    onFinishCargaArchivos()
      .then((values) => {
        setbotonEnviarAdjuntosPerfil(false);
        formAdjuntosPerfil.resetFields();
        notification["warning"]({
          message: "Adjuntos",
          description: "Adjuntos cargados con exito.",
        });
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setbotonEnviarAdjuntosPerfil(false);
      });
  };

  const onFinishCargaArchivos = async () => {
    const newArray = item.map((item) => parseInt(item.id));

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    console.log("Datos a subir nuevos archivos", newArray);

    let bodyContent = JSON.stringify({
      data: {
        adjuntos: newArray,
      },
    });

    try {
      const { data } = await Axios.put(
        `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
        bodyContent,
        { headers }
      );
      setUpdate(!update);
      return data;
    } catch (error) {
      console.error("Error al cargar archivos:", error);
      // Manejar el error según tus necesidades (lanzar, registrar, etc.)
    }
  };

  const onFinishFailedAdjuntosPerfil = (errorInfo) => {
    notification["warning"]({
      message: "Comentarios",
      description: "No se pudieron cargar los comentarios.",
    });
  };

  const enviarCorreoProgramarEntrevista = async (
    comentario,
    link,
    fecha,
    hora
  ) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga.attributes.mother.data.attributes.correo,
      nombre: datosCarga.attributes.mother.data.attributes.nombre,
      asunto: "Programacion Entrevista Reclutamiento Pronto BPO",
      mensaje: comentario,
      fecha: fecha.format("YYYY-MM-DD"),
      link: link,
      hora: hora.format("LTS"),
    };

    const datos = JSON.stringify(datosEnviar);

    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/programarEntrevistaTemplate`,
      datos,
      { headers }
    );
    return data;
  };

  const verificacionFormularioProgramarEntrevista2 = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${datosCarga.attributes.mother.data.id}`,
      { headers }
    );
    return data;
  };

  const verificacionFormularioProgramarEntrevista = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${datosCarga.attributes.mother.data.id}`,
      { headers }
    );
    return data;
  };

  const envioAsignarEntrevista = async (
    valoresAsignarEntrevista,
    idAsignados
  ) => {
    console.log("valores de entrevista a programar", valoresAsignarEntrevista);

    const enviarProgramarEntrevista = {
      data: {
        fechaEntrevista:
          valoresAsignarEntrevista["fechaAsignacionEntrevistas"].format(
            "YYYY-MM-DD"
          ),
        horaEntrevista:
          valoresAsignarEntrevista["horaAsignacionEntrevistas"].format(
            "HH:mm:ss"
          ),
        asignadoEntrevista: correoLogeado,
        estado: "Pendiente",
      },
    };

    const enviarProgramarEntrevistaStringifyados = JSON.stringify(
      enviarProgramarEntrevista
    );

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/asignados/${idAsignados}`,
      enviarProgramarEntrevistaStringifyados,
      { headers }
    );

    enviarCorreoProgramarEntrevista(
      valoresAsignarEntrevista.comentariosAsignarEntrevista,
      valoresAsignarEntrevista.linkAsignarEntrevista,
      valoresAsignarEntrevista.fechaAsignacionEntrevistas,
      valoresAsignarEntrevista.horaAsignacionEntrevistas
    );

    agregadoLogProgramarEntrevista();

    return data;
  };

  //////LogPPP
  const agregadoLogProgramarEntrevista = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Programacion de entrevista",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga.attributes.mother.data.id,
      },
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    return data;
  };
  ////Final LogPPP

  const onFinishEnvioAsignarEntrevista = (values) => {
    setbotonAsignarEntrevista(true);

    verificacionFormularioProgramarEntrevista().then((verificacion) => {
      console.log(
        "Obtencion de la verificacion de asignacion tipo entrevista",
        verificacion
      );

      if (verificacion.data.length === 0) {
        notification["info"]({
          message: "Recrutify - Error",
          description: `debe agregar primero puesto, departamento y entrevista al aplicante para poder programar entrevista`,
        });

        setbotonAsignarEntrevista(false);
      } else {
        verificacionFormularioProgramarEntrevista2().then((verificacion2) => {
          console.log(
            "Obtencion de verificacion de entrevista programada",
            verificacion2
          );

          if (
            verificacion2.data[0].attributes.asignadoEntrevista === null ||
            verificacion2.data[0].attributes.asignadoEntrevista === 0
          ) {
            envioAsignarEntrevista(values, verificacion2.data[0].id)
              .then((values) => {
                setbotonAsignarEntrevista(false);
                formAsignarEntrevista.resetFields();
                setmodalSucessAsignarEntrevista(true);
              })
              .catch((error) => {
                console.error("Error!", error);
                notification["error"]({
                  message: "Recrutify",
                  description: `${error}`,
                });
                setbotonAsignarEntrevista(false);
              });
          } else {
            notification["info"]({
              message: "Recrutify - Error",
              description: `el aplicante ya tiene programada una entrevista!`,
            });

            setbotonAsignarEntrevista(false);
            formAsignarEntrevista.resetFields();
          }
        });
      }
    });
  };

  const onFinishFailesAsignarEntrevista = (errorInfo) => {
    notification["warning"]({
      message: "Programacion Entrevista",
      description: "No se pudo programar entrevista",
    });
  };

  const actualizarEstadoEntrevista = async () => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Entrevista",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );
    return dataStatus;
  };

  const datosFormularioPP = async (valores) => {
    const datosEnviar = {
      data: {
        Proyecto: JSON.stringify(valores.proyecto),
        Puesto: valores.puesto,
        Entrevista: valores.tipoEntrevista,
        idMother: datosCarga.attributes.mother.data.id,
        mother: datosCarga.attributes.mother.data.id,
      },
    };

    const datos = JSON.stringify(datosEnviar);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/asignados",
      datos,
      { headers }
    );

    enviarCorreoPPE();
    agregadoLog();

    return data;
  };

  const verificacionformularioPP = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${datosCarga.attributes.mother.data.id}`,
      { headers }
    );
    return data;
  };

  const enviarCorreoPPE = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga?.attributes.mother.data.attributes.correo,
      nombre: datosCarga?.nombre,
      asunto: "Asignacion Entrevista Reclutamiento Pronto BPO",
      mensaje:
        "Se te ah asignado un proceso de entrevista, se te estara compartiendo la fecha, hora y modalidad para que se pueda llevar acabo la entrevista con uno de nuestros reclutadores PRONTO BPO",
    };

    const datos = JSON.stringify(datosEnviar);
    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/asignacionEntrevista`,
      datos,
      { headers }
    );
    return data;
  };

  //////LogPPP
  const agregadoLog = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Asignacion Proyecto, Puesto, Entrevista",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga.attributes.mother.data.id,
      },
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    return data;
  };
  ////Final LogPPP

  // Envio de formulario ???
  const envioFormularioPP = (values) => {
    setbotonEnvioPP(true);

    verificacionformularioPC()
      .then((verificacion) => {
        if (verificacion.data.length >= 1) {
          verificacionformularioPP()
            .then((verificacion) => {
              if (verificacion.data.length >= 1) {
                notification["info"]({
                  message: "Recrutify - Error",
                  description: `ya se le agrego puesto, departamento y entrevista al aplicante`,
                });

                setbotonEnvioPP(false);
              } else {
                actualizarEstadoEntrevista();
                datosFormularioPP(values)
                  .then((values) => {
                    setbotonEnvioPP(false);
                    formPP.resetFields();
                    setmodalPP(false);
                    setModalSucess(true);
                  })
                  .catch((error) => {
                    console.error("Error!", error);
                    notification["error"]({
                      message: "Recrutify",
                      description: `${error}`,
                    });
                    setbotonEnvioPP(false);
                  });
                setbotonEnvioPP(false);
              }
            })
            .catch((error) => {
              console.error("Error!", error);
              notification["error"]({
                message: "Recrutify",
                description: `${error}`,
              });
              setbotonEnvioPP(false);
            });
        } else {
          notification["info"]({
            message: "Recrutify - Error",
            description: `Debe realizar verificacion de primer contacto para poder asignar proyecto, puesto y entrevista`,
          });

          setbotonEnvioPP(false);
        }
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setbotonEnvioPP(false);
      });
  };

  const fallaEnvioFormularioPP = (errorInfo) => {
    notification["warning"]({
      message: "Comentarios",
      description: "No se pudo asignar proyecto-puesto.",
    });
  };

  const EnviarOferta = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?[populate]=*`,
      { headers }
    );

    console.log("Datos de perfiles", data);

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${data.data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Datos de asignados", dataasignados);

    const tipoEntrevista = dataasignados.data.data[0].attributes.Entrevista;

    if (tipoEntrevista === "Maneja Solo") {
      history.push({
        pathname: "ConfirmarFormularioManejaSolo", ///Direccion a donde dirige
        search: `?id=${idRequerimiento}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Personas") {
      history.push({
        pathname: "ConfirmarFormularioManejaPersonas", ///Direccion a donde dirige
        search: `?id=${idRequerimiento}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Equipos") {
      history.push({
        pathname: "ConfirmarFormularioManejaEquipos", ///Direccion a donde dirige
        search: `?id=${idRequerimiento}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Areas") {
      history.push({
        pathname: "ConfirmarFormularioManejaAreas", ///Direccion a donde dirige
        search: `?id=${idRequerimiento}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    }
  };

  const verificacionformularioPC = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/primercontactos?filters[idMother][$eq]=${datosCarga.attributes.mother.data.id}`,
      { headers }
    );
    return data;
  };

  const fallaEnvioFormularioPC = (errorInfo) => {
    notification["warning"]({
      message: "Comentarios",
      description: "No se pudo asignar primera entrevista.",
    });
  };

  const actualizarEstadoConfirmacion = async () => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Confirmacion entrevista 1",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    return dataStatus;
  };

  const agregadoLogPC = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Confirmacion entrevista 1 - primer contacto",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga.attributes.mother.data.id,
      },
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    return data;
  };

  const datosFormularioPC = async (valores) => {
    const datosEnviar = {
      data: {
        idMother: datosCarga.attributes.mother.data.id,
        nombre: valores.nombre,
        dpi: valores.dpi,
        correo: valores.correo,
        nitTramitado: valores.nitTramitado,
        disponibilidadLaboral: valores.disponibilidadLaboral,
        restriccionHorario: valores.restriccionHorario,
        pretensionSalarial: valores.pretensionSalarial.toString(),
        explicacionPago: valores.explicacionPago,
      },
    };

    //console.log('Datos de primer contacto', datosEnviar);

    const datos = JSON.stringify(datosEnviar);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/primercontactos",
      datos,
      { headers }
    );

    agregadoLogPC();

    return data;
  };
  // Envio de formulario ???
  const envioFormularioPC = (values) => {
    setbotonEnvioPC(true);
    verificacionformularioPC()
      .then((verificacion) => {
        console.log("Verificacion de envio de primer contacto", verificacion);
        if (verificacion.data.length >= 1) {
          notification["info"]({
            message: "Recrutify - Error",
            description: `ya se realizo confirmacion de entrevista 1 puedes visualizar el formulario`,
          });
          setbotonEnvioPC(false);
          formPC.resetFields();
        } else {
          actualizarEstadoConfirmacion();
          datosFormularioPC(values)
            .then((values) => {
              setbotonEnvioPC(false);
              formPC.resetFields();
              setmodalPC(false);
              setModalSucessPC(true);
            })
            .catch((error) => {
              console.error("Error!", error);
              notification["error"]({
                message: "Recrutify",
                description: `${error}`,
              });
              setbotonEnvioPC(false);
            });
          setbotonEnvioPC(false);
        }
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setbotonEnvioPC(false);
      });
  };

  const [value, setValue] = useState(null);

  const formatter = (value) => {
    if (!value) return "";
    // Convertir el valor a string y asegurarse de tener dos decimales
    const formattedValue = Number(value).toFixed(2);
    // Separar parte entera y decimal
    const parts = formattedValue.split(".");
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `Q ${formattedInteger}.${parts[1]}`;
  };

  const parser = (value) => {
    // Limpiar el valor quitando todos los caracteres no numéricos excepto los puntos
    const cleanedValue = value.replace(/[^\d.-]/g, "");
    // Asegurar que no haya más de un punto decimal
    const decimalCount = cleanedValue.split(".").length - 1;
    if (decimalCount > 1) {
      return cleanedValue.replace(/\.+$/, ""); // Eliminar todos los puntos excepto el último
    }
    return cleanedValue;
  };

  const onChange = (value) => {
    setValue(value);
  };

  const estado = datosCarga?.attributes.estado;

  // Determinar el color basado en el estado
  const getColor = (estado) => {
    switch (estado) {
      case "Aplicado":
        return "#FE8710";
      case "Declinado":
        return "red";
      default:
        return "green";
    }
  };

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatos().then((val) => {
      console.log("Datos de la carga del perfil", val);

      formPC.setFieldsValue({
        nombre:
          val.data.attributes.mother.data.attributes.nombre +
          " " +
          val.data.attributes.mother.data.attributes.apellido,
        dpi: val.data.attributes.mother.data.attributes.dpi,
        correo: val.data.attributes.mother.data.attributes.correo,
      });

      cargaDatosComentarios(val.data.attributes.mother.data.id).then(
        (idMother) => {}
      );
    });
  }, []);

  useEffect(() => {
    CargaProyectoPuesto();
    cargaArchivos();
  }, [update]);

  /////Pinto en pantalla
  return (
    <>
      <PageHeader
        onBack={() => enviarPagina()}
        title={"Perfil"}
        subTitle={false}
      />

      <Row justify="space-evenly" align="top">
        <Col span={4}>
          <Title level={4}>
            Estado Aplicante:{" "}
            <span style={{ color: getColor(estado) }}>{estado}</span>
          </Title>
        </Col>
        <Col span={4}>
          <Title level={4}>
            Posicion Aplica:{" "}
            {datosCarga?.attributes.mother.data.attributes.posicionAplica}
          </Title>
        </Col>
        {contratado && (
          <Col span={6}>
            <Title level={4}>
              Cuenta Contratado:{" "}
              <span style={{ color: getColor(estado) }}>{pcontratado}</span>
            </Title>
          </Col>
        )}
      </Row>

      <br />

      <Row>
        <Col span={12}>
          <Tabs onChange={callback} type="card" className="lineasBorde">
            <TabPane
              tab="Resumen Candidato / Experiencia"
              key="1"
              className="margenAdentro"
            >
              <div className="tarjetasTickets">
                <Meta title={"Experiencia"} />
                <br />

                <Row gutter={16}>
                  <Col span={7}>
                    <Title level={5}>
                      Empresa:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .empleador1 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={10}>
                    <Title level={5}>
                      Puesto:{" "}
                      <Text className="textInfoNoBold">
                        {" "}
                        {datosCarga?.attributes.mother.data.attributes
                          .puesto1 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={7}>
                    <Title level={5}>
                      Fecha Inicio:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .fechaInicio1 || "N/A"}
                      </Text>
                    </Title>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={7}>
                    <Title level={5}>
                      Empresa:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .empleador2 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={10}>
                    <Title level={5}>
                      Puesto:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .puesto2 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={7}>
                    <Title level={5}>
                      Fecha Inicio:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .fechaInicio2 || "N/A"}
                      </Text>
                    </Title>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={7}>
                    <Title level={5}>
                      Empresa:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .empleador3 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={10}>
                    <Title level={5}>
                      Puesto:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .puesto3 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={7}>
                    <Title level={5}>
                      Fecha Inicio:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .fechaInicio3 || "N/A"}
                      </Text>
                    </Title>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={7}>
                    <Title level={5}>
                      Empresa:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .empleador4 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={10}>
                    <Title level={5}>
                      Puesto:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .puesto4 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={7}>
                    <Title level={5}>
                      Fecha Inicio:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .fechaInicio4 || "N/A"}
                      </Text>
                    </Title>
                  </Col>
                </Row>

                <Divider />
                <Meta title={"Educacion"} />
                <br />

                <Row gutter={16}>
                  <Col span={11}>
                    <Title level={5}>
                      Nombre Institucion:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .nombreInstitucion1 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={7}>
                    <Title level={5}>
                      Titulo Recibido:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .tituloRecibido1 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={6}>
                    <Title level={5}>
                      Certificado Fisico:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .certificadoFisico1 || "N/A"}
                      </Text>
                    </Title>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={11}>
                    <Title level={5}>
                      Nombre Institucion:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .nombreInstitucion2 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={7}>
                    <Title level={5}>
                      Titulo Recibido:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .tituloRecibido2 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={6}>
                    <Title level={5}>
                      Certificado Fisico:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .certificadoFisico2 || "N/A"}
                      </Text>
                    </Title>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={11}>
                    <Title level={5}>
                      Nombre Institucion:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .nombreInstitucion3 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={7}>
                    <Title level={5}>
                      Titulo Recibido:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .tituloRecibido3 || "N/A"}
                      </Text>
                    </Title>
                  </Col>

                  <Col span={6}>
                    <Title level={5}>
                      Certificado Fisico:{" "}
                      <Text className="textInfoNoBold">
                        {datosCarga?.attributes.mother.data.attributes
                          .certificadoFisico3 || "N/A"}
                      </Text>
                    </Title>
                  </Col>
                </Row>
              </div>

              <Row gutter={[48, 24]} style={{ marginTop: 20 }}>
                <Col span={4} offset={10}>
                  <Button
                    onClick={() => EnviarVisualSolicitud(datosCarga?.id)}
                    size="Large"
                    type="primary"
                    htmlType="submit"
                  >
                    Verificar Formulario
                  </Button>
                </Col>
              </Row>

              <br />
            </TabPane>

            <TabPane tab="Visual CV" key="2" className="margenAdentro">
              Visualizador de Curriculum y Adjuntos
              <Vista idPerfil={idRequerimiento}></Vista>
            </TabPane>

            <TabPane
              tab="Adjuntar Documentos Extras"
              key="3"
              className="margenAdentro"
            >
              <Form
                name="solicitudEmpleo"
                form={formAdjuntosPerfil}
                onFinish={onFinishAdjuntosPerfil}
                onFinishFailed={onFinishFailedAdjuntosPerfil}
                autoComplete="off"
                requiredMark={true}
                initialValues={" "}
              >
                <Row gutter={[24, 18]}>
                  <Col offset={1}>
                    <Form.Item name="adjuntos" id="adjuntos">
                      <Upload
                        listType="picture"
                        customRequest={uploadFile}
                        onRemove={onRemoveUpoload}
                        multiple
                      >
                        <Button icon={<UploadOutlined />}>
                          Subir Archivos
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24, 18]} justify="space-around" align="middle">
                  <Col span={3}>
                    <Form.Item>
                      <Button
                        loading={botonEnviarAdjuntosPerfil}
                        size="Large"
                        type="primary"
                        htmlType="submit"
                      >
                        Cargar Archivos
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <List
                  header={<div>Archivos Adjuntos</div>}
                  bordered
                  dataSource={item}
                  renderItem={(archivo) => (
                    <List.Item>
                      <Space>
                        <Tooltip title="Ver">
                          <Button
                            href={
                              "https://rh.api.prontobpo.cloud" +
                                archivo?.attributes?.url || archivo.url
                            }
                            target="_blank"
                            shape="circle"
                            icon={<EyeOutlined />}
                            size="large"
                          />
                        </Tooltip>
                        <Text strong>
                          {archivo?.attributes?.name || archivo.name}
                        </Text>
                      </Space>
                    </List.Item>
                  )}
                />
              </Space>
            </TabPane>
          </Tabs>
        </Col>

        <Col span={4}>
          <Tabs onChange={callback} type="card" className="lineasBorde">
            <TabPane tab="Detalles" key="1" className="margenAdentro">
              <Row>
                <Col span={24}>
                  <Title level={5}>
                    Nombre:{" "}
                    <Text className="textInfoNoBold">
                      {" "}
                      {datosCarga?.attributes.mother.data.attributes?.nombre ||
                        "N/A"}
                    </Text>
                  </Title>
                </Col>

                <Col span={24}>
                  <Title level={5}>
                    Apellido:{" "}
                    <Text className="textInfoNoBold">
                      {" "}
                      {datosCarga?.attributes.mother.data.attributes
                        ?.apellido || "N/A"}
                    </Text>
                  </Title>
                </Col>

                <Col span={24}>
                  <Title level={5}>
                    Correo:{" "}
                    <Text className="textInfoNoBold" copyable>
                      {datosCarga?.attributes.mother.data.attributes?.correo ||
                        "N/A"}
                    </Text>
                  </Title>
                </Col>

                <Col span={24}>
                  <Title level={5}>
                    Telefono:{" "}
                    <Text className="textInfoNoBold" copyable>
                      {datosCarga?.attributes.mother.data.attributes
                        ?.telefono || "N/A"}
                    </Text>
                  </Title>
                </Col>

                <Col span={24}>
                  <Title level={5}>
                    Como se entero:{" "}
                    <Text className="textInfoNoBold">
                      {datosCarga?.attributes.mother.data.attributes
                        ?.enteroNosotros || "N/A"}
                    </Text>
                  </Title>
                </Col>

                <Col span={24}>
                  <Title level={5}>
                    Posicion Aplica:{" "}
                    <Text className="textInfoNoBold">
                      {datosCarga?.attributes.mother.data.attributes
                        ?.posicionAplica || "N/A"}
                    </Text>
                  </Title>
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col>
                  <Title level={5}>Accesos Directos</Title>
                </Col>
              </Row>

              <Row gutter={[48, 24]}>
                <Col>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: "flex" }}
                  >
                    <Button
                      loading={false}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                      onClick={() => validacionPCEffect()}
                      icon={<SolutionOutlined style={{ fontSize: "16px" }} />}
                    >
                      Primera Entrevista
                    </Button>
                    <Button
                      loading={false}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                      onClick={setmodalEntrevista}
                      icon={<TeamOutlined style={{ fontSize: "16px" }} />}
                      hidden
                    >
                      Entrevista
                    </Button>
                    {Easignado && (
                      <Button
                        size="Large"
                        type="primary"
                        htmlType="submit"
                        onClick={() => EnviarOferta()}
                        icon={<CarryOutOutlined style={{ fontSize: "16px" }} />}
                      >
                        Enviar a Oferta
                      </Button>
                    )}
                    {VEntrevista && (
                      <Button
                        size="Large"
                        type="default"
                        htmlType="button"
                        onClick={() => EnviarOferta()}
                        icon={<CarryOutOutlined style={{ fontSize: "16px" }} />}
                      >
                        Visualizar Entrevista
                      </Button>
                    )}
                    <Button
                      loading={false}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                      onClick={() => cargaHistorial()}
                      icon={<FileSearchOutlined style={{ fontSize: "16px" }} />}
                    >
                      Historial Aplicante
                    </Button>
                  </Space>
                </Col>

                <Col>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: "flex" }}
                  >
                    <Popconfirm
                      title="Declinar el perfil"
                      onConfirm={denegarSolicitudEmpleo}
                      okText="Si, declinar"
                      okType="danger"
                      cancelText="No"
                      icon={
                        <StopOutlined
                          style={{ fontSize: "16px", color: "#ff0000" }}
                        />
                      }
                    >
                      <Button
                        loading={false}
                        size="Large"
                        htmlType="submit"
                        danger
                        icon={<StopOutlined style={{ fontSize: "16px" }} />}
                      >
                        Declinar Perfil
                      </Button>
                    </Popconfirm>

                    <Popconfirm
                      title="Borrar Registro"
                      onConfirm={bajaRegistro}
                      okText="Si, borrar"
                      okType="danger"
                      cancelText="No"
                      icon={
                        <DeleteOutlined
                          style={{ fontSize: "16px", color: "#ff0000" }}
                        />
                      }
                    >
                      <Button
                        loading={false}
                        size="Large"
                        type="primary"
                        htmlType="submit"
                        danger
                        icon={<DeleteOutlined style={{ fontSize: "16px" }} />}
                      >
                        Borrar Registro
                      </Button>
                    </Popconfirm>
                  </Space>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>

        <Col span={8}>
          <Tabs onChange={callback} type="card" className="lineasBorde">
            <TabPane tab="Comentarios" key="1" className="margenAdentro">
              <div className="comentarios">
                {datosCargaComentarios &&
                  datosCargaComentarios.map((datosPlasmado) => (
                    <Row key={datosPlasmado.id}>
                      <Col span={24}>
                        <Title level={5}>
                          {datosPlasmado.attributes.persona}
                        </Title>
                      </Col>

                      <Col span={22} offset={1}>
                        <Text>{datosPlasmado.attributes.comentario}</Text>
                      </Col>

                      <Col span={4} offset={14}>
                        <Text>{datosPlasmado.attributes.fecha}</Text>
                      </Col>

                      <Col span={6}>
                        <Text>{datosPlasmado.attributes.hora}</Text>
                      </Col>

                      <br />
                      <br />
                    </Row>
                  ))}
              </div>

              <Divider />

              <div>
                <Form
                  name="solicitudEmpleo"
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  requiredMark={true}
                  initialValues={" "}
                >
                  <Row>
                    <Col span={22} offset={1}>
                      <Form.Item
                        className="username"
                        label="Ingresa Comentario"
                        name="comentariosPerfil"
                        rules={[
                          {
                            required: true,
                            message: "Indicar el comentario",
                          },
                        ]}
                      >
                        <TextArea
                          size="large"
                          placeholder="Ingrese comentario"
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />

                  <Row>
                    <Col span={8} offset={8}>
                      <Button
                        loading={botonEnviarComentario}
                        size="Large"
                        type="primary"
                        htmlType="submit"
                      >
                        Enviar Comentario
                      </Button>
                    </Col>
                  </Row>
                  <br />
                </Form>
              </div>
            </TabPane>

            <TabPane
              tab="Programar Fecha Entrevista"
              key="2"
              className="margenAdentro"
            >
              <div>
                <Form
                  name="solicitudEmpleo"
                  form={formAsignarEntrevista}
                  onFinish={onFinishEnvioAsignarEntrevista}
                  onFinishFailed={onFinishFailesAsignarEntrevista}
                  autoComplete="off"
                  requiredMark={true}
                  initialValues={" "}
                >
                  <Row>
                    <Col span={20} offset={2}>
                      <Form.Item
                        className="username"
                        label="Fecha para realizar entrevista"
                        name="fechaAsignacionEntrevistas"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor ingresa la fecha para entrevistar.",
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          placeholder="Fecha Inicio de Labores"
                          format="DD/MM/YYYY"
                          locale={locale}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={20} offset={2}>
                      <Form.Item
                        className="username"
                        label="Hora para realizar entrevista"
                        name="horaAsignacionEntrevistas"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor ingresa la hora para entrevistar.",
                          },
                        ]}
                      >
                        <TimePicker />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={20} offset={2}>
                      <Form.Item
                        className="username"
                        label="Ingresa comentario a enviar en el correo de cita"
                        name="comentariosAsignarEntrevista"
                        rules={[
                          {
                            required: true,
                            message:
                              "Indicar el comentario para programar entrevista",
                          },
                        ]}
                        labelCol={{ span: 24 }} // Hace que el label ocupe toda la fila
                        wrapperCol={{ span: 24 }} // Asegura que el TextArea inicie en una nueva línea
                      >
                        <TextArea
                          size="large"
                          placeholder="Ingrese comentario"
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Col offset={2}>
                    <Switch
                      onChange={cambioEntrevista}
                      checkedChildren="Virtual"
                      unCheckedChildren="Virtual"
                    />
                  </Col>

                  <br />

                  {virtual === true && (
                    <Row className="LinkVisualEntrevista">
                      <Col span={20} offset={2}>
                        <Form.Item
                          className="username"
                          label="Link para la reunion"
                          name="linkAsignarEntrevista"
                          rules={[
                            {
                              required: true,
                              message: "Colocar link para programar entrevista",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Link" />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <br />

                  <Row>
                    <Col span={8} offset={8}>
                      <Button
                        loading={botonAsignarEntrevista}
                        size="Large"
                        type="primary"
                        htmlType="submit"
                      >
                        Programar Entrevista
                      </Button>
                    </Col>
                  </Row>

                  <br />
                </Form>
              </div>
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      {
        // MODAL PROYECTO Y PUESTO
        <TipoForm
          modalPP={modalPP}
          setmodalPP={setmodalPP}
          cecosNuevos={cecosNuevos}
          puestos={puestos}
          envioFormularioPP={envioFormularioPP}
          fallaEnvioFormularioPP={fallaEnvioFormularioPP}
          botonEnvioPP={botonEnvioPP}
        />
      }

      {
        // MODAL Entrevista
        <Entrevista
          modalEntrevista={modalEntrevista}
          setmodalEntrevista={setmodalEntrevista}
          idRequerimiento={idRequerimiento}
          correoUsuario={correoLOgueado}
        />
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalPC}
          onOk={() => setmodalPC(false)}
          onCancel={() => setmodalPC(false)}
          height={1000}
          width={1200}
          footer={
            <Button type="primary" onClick={() => setmodalPC(false)}>
              Salir
            </Button>
          }
        >
          <Row>
            <Col className="gutter-row" span={20} offset={3}>
              <Title level={3}>Confirmacion primer contacto</Title>
            </Col>
          </Row>

          <br />

          <Row gutter={16}>
            <Col className="gutter-row" span={22} offset={1}>
              <Form
                name="EnvioProyectoPuesto"
                form={formPC}
                onFinish={envioFormularioPC}
                onFinishFailed={fallaEnvioFormularioPC}
                autoComplete="off"
                requiredMark={true}
                initialValues={" "}
              >
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Nombre Completo"
                      name="nombre"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa tu nombre.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Nombre" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="DPI"
                      name="dpi"
                      rules={[
                        {
                          required: true,
                          message: "DPI.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="DPI" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Correo Electronico"
                      name="correo"
                      rules={[
                        {
                          required: true,
                          message: "DPI.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="DPI" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Tiene su nit tramitado"
                      name="nitTramitado"
                      rules={[
                        {
                          required: true,
                          message: "Si / No",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Si / No" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Disponibilidad para empezar a laborar"
                      name="disponibilidadLaboral"
                      rules={[
                        {
                          required: true,
                          message: "Disponibilidad.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Disponibilidad" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Alguna restriccion de horario"
                      name="restriccionHorario"
                      rules={[
                        {
                          required: true,
                          message: "Retriccion Horario",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Retriccion Horario" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Pretension Salarial"
                      name="pretensionSalarial"
                      rules={[
                        {
                          required: true,
                          message: "Pretension Salarial.",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Pretension Salarial"
                        style={{ width: "200px" }}
                        size="large"
                        value={value}
                        formatter={formatter}
                        parser={parser}
                        onChange={onChange}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Explicacion de las fechas de pago"
                      name="explicacionPago"
                      rules={[
                        {
                          required: true,
                          message: "Explicacion Pago",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Explicacion Pago" />
                    </Form.Item>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col span={10} offset={11}>
                    <Button
                      loading={botonEnvioPC}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar Datos
                    </Button>
                  </Col>
                </Row>
              </Form>

              <br />
              <br />
              <Row>
                <Col span={10} offset={4}>
                  <Button
                    loading={false}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                      visualPC(datosCarga.attributes.mother.data.id)
                    }
                  >
                    Visualizar Form Primer Contacto
                  </Button>
                </Col>

                <Col span={10}>
                  <Button
                    loading={false}
                    size="Large"
                    type="primary"
                    htmlType="submit"
                    onClick={() => setmodalPP(true)}
                  >
                    Asignar Proyecto, Puesto y Entrevista
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalVPC}
          onOk={() => setmodalVPC(false)}
          onCancel={() => setmodalVPC(false)}
          height={1000}
          width={1200}
          footer={
            <Button type="primary" onClick={() => setmodalVPC(false)}>
              Salir
            </Button>
          }
        >
          <Row>
            <Col className="gutter-row" span={20} offset={3}>
              <Title level={3}>Visualizacion primer contacto</Title>
            </Col>
          </Row>

          <br />

          <Row gutter={16}>
            <Col className="gutter-row" span={22} offset={1}>
              <Form
                name="EnvioProyectoPuesto"
                form={formVPC}
                onFinish={envioFormularioPC}
                onFinishFailed={fallaEnvioFormularioPC}
                autoComplete="off"
                requiredMark={true}
                initialValues={" "}
              >
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Nombre"
                      name="nombreV"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa tu nombre.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Nombre" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="DPI"
                      name="dpiV"
                      rules={[
                        {
                          required: true,
                          message: "DPI",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="DPI" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Correo Electronico"
                      name="correoV"
                      rules={[
                        {
                          required: true,
                          message: "DPI.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="DPI" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Tiene su nit tramitado"
                      name="nitTramitadoV"
                      rules={[
                        {
                          required: true,
                          message: "Si / No",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Si / No" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Disponibilidad para empezar a laborar"
                      name="disponibilidadLaboralV"
                      rules={[
                        {
                          required: true,
                          message: "Disponibilidad.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Disponibilidad" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Alguna restriccion de horario"
                      name="restriccionHorarioV"
                      rules={[
                        {
                          required: true,
                          message: "Retriccion Horario",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Retriccion Horario" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Pretension Salarial"
                      name="pretensionSalarialV"
                      rules={[
                        {
                          required: true,
                          message: "Pretension Salarial.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Pretension Salarial" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Explicacion de las fechas de pago"
                      name="explicacionPagoV"
                      rules={[
                        {
                          required: true,
                          message: "Explicacion Pago",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Explicacion Pago" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalHistorial}
          onOk={() => setmodalHistorial(false)}
          onCancel={() => setmodalHistorial(false)}
          height={600}
          width={1000}
          footer={
            <Button type="primary" onClick={() => setmodalHistorial(false)}>
              Salir
            </Button>
          }
        >
          <Row>
            <Col className="gutter-row" span={20} offset={3}>
              <Title level={3}>Historial Aplicante</Title>
            </Col>
          </Row>
          <br />

          <Row gutter={16}>
            <Col className="gutter-row" span={22} offset={1}>
              {datosCargaHistorial && (
                <Timeline mode="alternate">
                  {datosCargaHistorial.map((datosPlasmado) => (
                    <Timeline.Item key={datosPlasmado.id}>
                      <Row>
                        <Col span={10}>
                          <Title level={5}>
                            {datosPlasmado.attributes.funcion}
                          </Title>
                        </Col>
                        <Col span={6}>
                          <Title level={5}>
                            {datosPlasmado.attributes.nombre}
                          </Title>
                        </Col>
                        <Col span={4}>
                          <Text>
                            {moment(datosPlasmado.attributes.fecha)
                              .subtract(1, "days")
                              .format("D-M-Y")}
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text>
                            {datosPlasmado.attributes.hora.substring(0, 5)}
                          </Text>
                        </Col>
                      </Row>
                    </Timeline.Item>
                  ))}
                </Timeline>
              )}
            </Col>
          </Row>
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalSucess}
          onOk={() => setModalSucess(false)}
          onCancel={() => setModalSucess(false)}
          footer={
            <Button type="primary" onClick={() => setModalSucess(false)}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Confirmacion de asignado Proyecto y Puesto!" />
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalSucessPC}
          onOk={() => setModalSucessPC(false)}
          onCancel={() => setModalSucessPC(false)}
          footer={
            <Button type="primary" onClick={() => setModalSucessPC(false)}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Confirmacion de primera entrevista!" />
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalSucessAsignarEntrevista}
          onOk={() => setmodalSucessAsignarEntrevista(false)}
          onCancel={() => setmodalSucessAsignarEntrevista(false)}
          footer={
            <Button
              type="primary"
              onClick={() => setmodalSucessAsignarEntrevista(false)}
            >
              Salir
            </Button>
          }
        >
          <Sucess titulo="Entrevista Programada con exito, se ah enviado un correo a nuestro aplicante!" />
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalSucessdenegado}
          onOk={() => setModalSucessdenegado(false)}
          onCancel={() => setModalSucessdenegado(false)}
          footer={
            <Button
              type="primary"
              onClick={() => setModalSucessdenegado(false)}
            >
              Salir
            </Button>
          }
        >
          <Sucess titulo="Formulario denegado!" />
        </Modal>
      }
    </>
  );
  /////Fin pinto en pantalla
};

export default FormularioPerfil;
